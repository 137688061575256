// extracted by mini-css-extract-plugin
export var bottomText = "UsefuInformation-module--bottomText--YG9-D";
export var contentWrapper = "UsefuInformation-module--contentWrapper--qonbL";
export var contentWrapperShow = "UsefuInformation-module--contentWrapperShow--RRShb";
export var galleryImage = "UsefuInformation-module--galleryImage--AiOtx";
export var galleryWrapper = "UsefuInformation-module--galleryWrapper--2ZtYd";
export var heading = "UsefuInformation-module--heading--PdOb+";
export var iconArowwWrapper = "UsefuInformation-module--iconArowwWrapper--13Fl-";
export var iconArowwWrapperRotate = "UsefuInformation-module--iconArowwWrapperRotate--rufjn";
export var informationSectionWrapper = "UsefuInformation-module--informationSectionWrapper--WkgPJ";
export var logoImage = "UsefuInformation-module--logoImage--o40KL";
export var logosWrapper = "UsefuInformation-module--logosWrapper--KW2X4";
export var mainImage = "UsefuInformation-module--mainImage--sS32I";
export var mainText = "UsefuInformation-module--mainText--v0P8t";