import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { Context } from "../context/SiteProvider"
// import InformationSection from "../components/information-section/InformationSection"
import UsefuInformation from "../components/useful-information/UsefuInformation"
import Layout from "../components/layout/Layout"
import SectionHeading from "../components/UI/section-heading/SectionHeading"

const query = graphql`
  query {
    allStrapiUseful {
      nodes {
        section_heading {
          data {
            section_heading
          }
        }
        useful_information {
          id
          main_heading {
            data {
              main_heading
            }
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
          text {
            data {
              text
            }
          }
          gallery {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          bottom_text {
            data {
              bottom_text
            }
          }
          logos {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          order
        }
      }
    }
  }
`

const Useful = () => {
  // const isBrowser = typeof window !== "undefined"
  const usefulData = useStaticQuery(query)
  const data = usefulData.allStrapiUseful.nodes[0].useful_information
  const sectionHeading =
    usefulData.allStrapiUseful.nodes[0].section_heading.data.section_heading
  // const ctx = useContext(Context)

  // if (isBrowser) {
  //   window.onbeforeunload = function () {
  //     window.scrollTo(0, 0)
  //   }
  // }

  // useEffect(() => {
  //   if (ctx.findPost && isBrowser) {
  //     setTimeout(() => {
  //       const post = document.getElementById(ctx.findPost)
  //       post.scrollIntoView({
  //         behavior: "auto",
  //         block: "center",
  //         inline: "center",
  //       })
  //       ctx.setFindpost(null)
  //     }, 500)
  //   }
  // }, [])

  const reversed = [...data].reverse()

  return (
    <Layout>
      <SectionHeading heading={sectionHeading} mode="useful" />

      {reversed.map(item => {
        return (
          <div key={item.id}>
            <UsefuInformation id={item.id} data={item} />
          </div>
        )
      })}
    </Layout>
  )
}

export default Useful
