import React, { useState } from "react"
import * as Style from "./UsefuInformation.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Markdown from "react-markdown"
import { FaArrowAltCircleDown } from "react-icons/fa"

const UsefuInformation = ({ data, id }) => {
  const [show, setShow] = useState(false)
  return (
    <section className={`${Style.informationSectionWrapper} main-container`}>
      {/* HEADING */}
      {data?.main_heading?.data?.main_heading && (
        <div
          onClick={() => {
            setShow(!show)
          }}
          id={id}
          className={Style.heading}
        >
          <Markdown children={data?.main_heading?.data?.main_heading} />
          <div
            className={`${Style.iconArowwWrapper} ${
              show && Style.iconArowwWrapperRotate
            }`}
          >
            <FaArrowAltCircleDown color="rgba(110, 191, 155, 1)" size={30} />
          </div>
        </div>
      )}
      <div
        className={`${Style.contentWrapper} ${
          show && Style.contentWrapperShow
        }`}
      >
        {/* MAIN IMAGE */}
        {data?.main_image?.localFile?.childImageSharp?.gatsbyImageData && (
          <div className={Style.mainImage}>
            <GatsbyImage
              image={
                data?.main_image?.localFile?.childImageSharp?.gatsbyImageData
              }
              alt="main_image"
            />
          </div>
        )}
        {/* MAIN TEXT */}
        {data?.text?.data?.text && (
          <div className={Style.mainText}>
            <Markdown children={data?.text?.data?.text} />
          </div>
        )}
        {/* GALLERY */}
        {data?.gallery && (
          <div className={Style.galleryWrapper}>
            {data?.gallery.map(item => {
              return (
                <div key={item.id} className={Style.galleryImage}>
                  {item?.localFile?.childImageSharp?.gatsbyImageData && (
                    <GatsbyImage
                      image={item?.localFile?.childImageSharp?.gatsbyImageData}
                      alt="main_image"
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}
        {/* BOTTOM TEXT */}
        {/* {data?.bottom_text?.data?.bottom_text && (
          <div className={Style.bottomText}>
            <Markdown children={data?.bottom_text?.data?.bottom_text} />
          </div>
        )} */}
        {/* LOGOS */}
        {data?.logos && (
          <div className={Style.logosWrapper}>
            {data?.logos.map(item => {
              return (
                <div key={item.id} className={Style.logoImage}>
                  {item?.localFile?.childImageSharp?.gatsbyImageData && (
                    <GatsbyImage
                      image={item?.localFile?.childImageSharp?.gatsbyImageData}
                      alt="logo_image"
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

export default UsefuInformation
